<template>
	<el-container class="chat-page">
		<el-aside class="chat-list-box" width="260px">
			<div class="chat-list-header">
				<el-input v-model="searchText" class="search-text" placeholder="搜索">
					<i slot="prefix" class="el-icon-search el-input__icon"> </i>
				</el-input>
			</div>
			<div v-if="loading"  v-loading="true" class="chat-list-loadding" element-loading-background="#eee"
				element-loading-spinner="el-icon-loading" element-loading-text="消息接收中...">
				<div class="chat-loading-box"></div>
			</div>
			<el-scrollbar class="chat-list-items">
				<div v-for="(chat,index) in chatStore.chats" :key="index">
					<chat-item v-show="chat.showName.includes(searchText) || (chat.remark!= null &&chat.remark.includes(searchText))" :active="chat === chatStore.activeChat" :chat="chat"
						:index="index" @change="onChangeKefu($event,chat,index)" @delete="onDelItem(index)"
						@edit="onEditRemark(chat,index)" @top="onTop(index)" @click.native="onActiveItem(index)">
          </chat-item>
				</div>
        <change-kefu ref="qrBox"  :qrs="kefus" @select="onQrSelect">
        </change-kefu>
			</el-scrollbar>
		</el-aside>
		<el-container class="chat-box">
			<chat-box ref="ref-chat-box" v-if="chatStore.activeChat" :chat="chatStore.activeChat" :iskefu="true" ></chat-box>
		</el-container>
	</el-container>
</template>

<script>
	import ChatItem from "../components/chat/ChatItem.vue";
	import ChatBox from "../components/chat/ChatBox.vue";
  import ChangeKefu from "../components/friend/ChangeKefu.vue";
	export default {
		name: "chat",
		components: {
			ChatItem,
			ChatBox,
      ChangeKefu
		},
		data() {
			return {
				searchText: "",
				messageContent: "",
				group: {},
				groupMembers: [],
        kefus: [],
			}
		},
		methods: {
			onActiveItem(index) {
				this.$store.commit("activeChat", index);
			},
			onDelItem(index) {
				this.$store.commit("removeChat", index);
			},
			onTop(chatIdx) {
				this.$store.commit("moveTop", chatIdx);
			},
      onChangeKefu(e,chat, idx) {
        if(chat.type === 'GROUP'){
          this.$message.success("群组不能切换！");
          return ;
        }
        if( this.kefus.length >0 ) {
          this.$refs.qrBox.friendId = chat.targetId;
          this.$refs.qrBox.idx = idx;
          this.$refs.qrBox.open({
            x: e.x,
            y: e.y
          })
        }else {
          this.$message.success("没有其它客服可切换！");
        }
      },
      onQrSelect(qr,friendId,idx) {
        this.$confirm(`客服确认切换为 '${qr.user_name}'吗?`, '确认切换?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: `/friend/changeKefu/${friendId}/${qr.user_id}`,
            method: 'put'
          }).then((data) => {
            this.$message.success("切换客服成功");
            this.$store.commit("removeFriend", idx);
            this.$store.commit("removePrivateChat", friendId);
          })
        }).catch(() =>{});
      },
			onEditRemark(chat,idx) {
        this.$prompt('请输入备注信息', '添加备注', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            inputValue: chat.remark,
            inputPattern: /\S/,
            inputErrorMessage: '请输入备注信息',
            inputType: 'textarea'
        }).then((o) => {
            let data = {
              id: chat.targetId,
              remark: o.value
            }
            let url = '/friend/update/remark';
            if(chat.type === "GROUP"){
                url = '/group/modify'
                data.name = chat.showName
            }
            this.$http({
                        url: url,
                        method: 'put',
                        data: data
            }).then((res) => {
                        let newchat = {}
                        newchat.targetId = chat.targetId;
                        newchat.remark = data.remark;
                        newchat.type = chat.type;
                        newchat.showName = chat.showName;
                        this.$store.commit('updateChat',newchat);
                        this.$store.dispatch("loadFriend");
                        this.$message.success("添加备注成功");
            }).catch(() =>{});

        }).catch(() =>{});
      },
      loadKefuInfo() {
        this.$http({
          url: `/user/find/kefu`,
          method: 'get'
        }).then((kefus) => {
          this.kefus = kefus;
        })
      },
		},
		computed: {
			chatStore() {
				return this.$store.state.chatStore;
			},
			loading(){
				return this.chatStore.loadingGroupMsg || this.chatStore.loadingPrivateMsg
			}
		},
    mounted() {
      this.loadKefuInfo();
    }
	}
</script>

<style lang="scss">
	.chat-page {
		.chat-list-box {
			display: flex;
			flex-direction: column;
			border: #dddddd solid 1px;
			background: white;
			width: 3rem;

			.chat-list-header {
				padding: 3px 8px;
				line-height: 50px;
				border-bottom: 1px #ddd solid;
				
				.el-input__inner {
					border-radius: 10px !important;
					background-color: #F8F8F8;
				}
				
			}
			
			.chat-list-loadding{
				height: 50px;
				background-color: #eee;
				
				.chat-loading-box{
					height: 100%;
				}
			}
			
			.chat-list-items {
				flex: 1;
				background: #F8F8F8;
				margin: 0 3px;
			}
		}
	}
</style>